import React from "react";
import Main from "../components/main";
import SEO from "../components/section/seo";
import ESafetyComponent from "../components/esafety";

export default function Esafety() {
  return (
  <Main >
    <SEO title="Be Accomodation - Internet" />
    <ESafetyComponent/>
  </Main>)
}